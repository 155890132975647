import { MemberProfile } from "@/services/core-api-adapter";
import {
  isFeatureEnabled,
  FeatureToggleIdentifier,
} from "@/services/feature-toggle-adapter";
import theme from "@/theme";
import { convertPxToRem } from "@/utils";
import { Box, Stack, Typography } from "@mui/material";
import MessagesIconCTA from "../MessagesIconCTA";
import {
  HealthCheckTile,
  BenefitsTile,
  HealthProfileTile,
} from "../DashboardTiles";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import IconLoader from "@/features/Common/components/IconLoader";
import MedicalAssistanceInfoPanel from "../MedicalAssistanceInfoPanel";
import useGetMemberProductPaymentRates from "@/features/Common/hooks/useGetMemberProductPaymentRates";
import LifestyleAndAdviceSection from "../LifestyleAndAdviceSection";

interface ThirdPartyMemberDashboardProps {
  memberProfile: MemberProfile | null;
  isMemberProfileLoading: boolean;
  memberProfileFetchError: boolean;
}
export default function ThirdPartyMemberDashboard({
  memberProfile,
  isMemberProfileLoading,
  memberProfileFetchError,
}: ThirdPartyMemberDashboardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    productPaymentRates,
    productPaymentRatesFetchError,
    isProductPaymentRatesLoading,
  } = useGetMemberProductPaymentRates();

  function onBellIconButtonClick() {
    navigate("/home/messages");
  }

  function onGetMedicalAssistanceButtonClick() {
    navigate("/home/get-medical-assistance/third-party");
  }

  return (
    <>
      <Box
        sx={{
          borderRadius: `0 0 ${convertPxToRem(30)}  ${convertPxToRem(30)}`,
          background: `linear-gradient(to bottom, ${theme.palette.accent2[100]}, ${theme.palette.accent2.light}, ${theme.palette.accent2.main})`,
          position: "absolute",
          height: convertPxToRem(170),
          width: "100%",
          left: 0,
          top: 0,
          zIndex: 1,
        }}
      />

      <Stack sx={{ zIndex: 2 }} spacing={2}>
        <Stack display="flex" alignItems="end" position="relative">
          <MessagesIconCTA onClick={onBellIconButtonClick} />
        </Stack>
        <Stack spacing={1}>
          <Typography
            variant="body1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(20),
            }}
          >
            {memberProfile && memberProfile?.firstName?.length > 0
              ? t("Home.title.withName", {
                  name: memberProfile.firstName,
                })
              : t("Home.title.withoutName")}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(24),
            }}
          >
            {t("Home.helpSubtitle")}
          </Typography>
          <Stack spacing={1}>
            <ButtonWithAnalytics
              page="Dashboard"
              text={t("GetMedicalAssistanceCTAButton.thirdParty.label")}
              intent="navigational"
              onClick={onGetMedicalAssistanceButtonClick}
              aria-label={t("GetMedicalAssistanceCTAButton.thirdParty.label")}
              color="primary"
              size="large"
              fullWidth
              endIcon={<IconLoader icon="ArrowInCircleIcon" />}
              style={{ justifyContent: "space-between" }}
            >
              <Stack spacing={1} textAlign="left">
                <Typography variant="h4" color="primary.contrastText">
                  {t("GetMedicalAssistanceCTAButton.thirdParty.label")}
                </Typography>
              </Stack>
            </ButtonWithAnalytics>
            {!isProductPaymentRatesLoading &&
              !productPaymentRatesFetchError &&
              productPaymentRates && (
                <MedicalAssistanceInfoPanel
                  productPaymentRates={productPaymentRates}
                />
              )}
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <Typography component="h2" fontWeight={600}>
            {t("common.myProfile")}
          </Typography>

          <Stack
            direction="row"
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
            gap={2}
          >
            <HealthCheckTile
              memberProfile={memberProfile}
              isLoading={isMemberProfileLoading}
              shouldShowServiceUsage={true}
            />
            <HealthProfileTile
              lastUpdatedDate={memberProfile?.healthProfile?.lastUpdatedAt}
              healthScore={
                Number(memberProfile?.healthProfile?.healthScoreValue) || 0
              }
              isLoading={isMemberProfileLoading}
              isError={memberProfileFetchError}
            />
            <BenefitsTile
              benefitCount={memberProfile?.benefitCount}
              isLoading={isMemberProfileLoading}
              isError={memberProfileFetchError}
            />
          </Stack>

          {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_CHAT_BOT) && (
            <LifestyleAndAdviceSection />
          )}
        </Stack>
      </Stack>
    </>
  );
}
