import { MemberProfile } from "@/services/core-api-adapter";
import {
  isFeatureEnabled,
  FeatureToggleIdentifier,
} from "@/services/feature-toggle-adapter";
import theme from "@/theme";
import { convertPxToRem } from "@/utils";
import { Box, Stack, Typography } from "@mui/material";
import MedicalAssistanceCTA from "../MedicalAssistanceCTA";
import MessagesIconCTA from "../MessagesIconCTA";
import {
  HealthCheckTile,
  BenefitsTile,
  HealthProfileTile,
  MyPeopleTile,
} from "../DashboardTiles";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import LifestyleAndAdviceSection from "../LifestyleAndAdviceSection";
import MembershipDashboardPanel from "@/features/Dashboard/components/MembershipDashboardPanel";
import useGetMemberships from "@/features/Common/hooks/useGetMemberships";

interface B2BMemberDashboardProps {
  memberProfile: MemberProfile | null;
  isMemberProfileLoading: boolean;
  memberProfileFetchError: boolean;
}
export default function B2BMemberDashboard({
  memberProfile,
  isMemberProfileLoading,
  memberProfileFetchError,
}: B2BMemberDashboardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    firstMembership,
    memberships,
    membershipsFetchError,
    isMembershipsLoading,
  } = useGetMemberships();

  function onBellIconButtonClick() {
    navigate("/home/messages");
  }

  return (
    <>
      <Box
        sx={{
          borderRadius: `0 0 ${convertPxToRem(30)}  ${convertPxToRem(30)}`,
          background: `linear-gradient(to bottom, ${theme.palette.accent2[100]}, ${theme.palette.accent2.light}, ${theme.palette.accent2.main})`,
          position: "absolute",
          height: convertPxToRem(170),
          width: "100%",
          left: 0,
          top: 0,
          zIndex: 1,
        }}
      />

      <Stack sx={{ zIndex: 2 }} spacing={1}>
        <Stack display="flex" alignItems="end" position="relative">
          <MessagesIconCTA onClick={onBellIconButtonClick} />
        </Stack>
        <Stack spacing={1}>
          <Typography
            variant="body1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(20),
            }}
          >
            {memberProfile && memberProfile?.firstName?.length > 0
              ? t("Home.title.withName", {
                  name: memberProfile.firstName,
                })
              : t("Home.title.withoutName")}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(24),
            }}
          >
            {t("Home.helpSubtitle")}
          </Typography>
          <Stack>
            <Stack sx={{ zIndex: 2 }}>
              <MedicalAssistanceCTA
                memberProfile={memberProfile}
                isLoading={isMemberProfileLoading}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <Typography component="h2" fontWeight={600}>
            {t("common.myProfile")}
          </Typography>

          <Stack
            direction="row"
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
            gap={2}
          >
            <HealthCheckTile
              memberProfile={memberProfile}
              isLoading={isMemberProfileLoading}
              shouldShowServiceUsage={true}
            />
            <HealthProfileTile
              lastUpdatedDate={memberProfile?.healthProfile?.lastUpdatedAt}
              healthScore={
                Number(memberProfile?.healthProfile?.healthScoreValue) || 0
              }
              isLoading={isMemberProfileLoading}
              isError={memberProfileFetchError}
            />
            <BenefitsTile
              benefitCount={memberProfile?.benefitCount}
              isLoading={isMemberProfileLoading}
              isError={memberProfileFetchError}
            />
            <MyPeopleTile
              membershipId={memberProfile?.membership.id}
              dependentsCount={memberProfile?.dependentCount}
              isLoading={isMemberProfileLoading}
              isError={memberProfileFetchError}
            />
          </Stack>

          {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_CHAT_BOT) && (
            <LifestyleAndAdviceSection />
          )}
        </Stack>
      </Stack>
      <Stack mt={4} spacing={5}>
        <MembershipDashboardPanel
          memberProfile={memberProfile}
          membershipData={{
            firstMembership,
            memberships,
            membershipsFetchError,
            isMembershipsLoading,
          }}
        />
      </Stack>
    </>
  );
}
