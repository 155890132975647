import { Button, Stack, Typography } from "@mui/material";
import {
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeartInShieldIcon from "../../../../theme/icons/HeartInShieldIcon";
import HeartIcon from "../../../../theme/icons/HeartIcon";
import CircleWithQuestionMark from "../../../../theme/icons/CircleWithQuestionMark";
import DotsVerticalIcon from "../../../../theme/icons/DotsVerticalIcon";
import HomeIcon from "../../../../theme/icons/HomeIcon";
import { NavigationConfig } from "../../../../theme";
import { convertPxToRem } from "../../../../utils";
import {
  trackEvent,
  EventPayload,
  trackUserInteraction,
  AnalyticsUserInteractionEventPayload,
} from "../../../../services/analytics-adapter";
import { useGlobalStore } from "@/store";
import {
  isFeatureEnabled,
  FeatureToggleIdentifier,
} from "@/services/feature-toggle-adapter";

interface NavigationItemProps {
  icon: React.JSX.Element;
  label: string;
  to: string;
  matchRoute?: string;
  entireUrlMatch?: boolean;
  analyticsPayload?: EventPayload;
  analyticsUserInteractionPayload?: AnalyticsUserInteractionEventPayload;
}

interface GetHelpNavigationItemProps {
  icon: React.JSX.Element;
  label: string;
  onClick?: () => void;
}

const GetHelpNavigationItem = ({
  icon,
  label,
  onClick,
}: GetHelpNavigationItemProps) => {
  return (
    <Button
      sx={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        p: 0,
        px: 0.5,
        height: NavigationConfig.navigationItemHeight,
        minWidth: NavigationConfig.navigationItemMinWidth,
        width: "100%",
        ":focus": {
          outline: "none",
        },
      }}
      color="neutral"
      variant="text"
      aria-label={label}
      onClick={onClick}
    >
      {icon}
      <Typography
        color="inherit"
        textAlign="center"
        component="p"
        variant="h6"
        sx={{
          mt: 0.5,
          flex: "0 0 auto",
          lineHeight: NavigationConfig.navigationItemLabelLineHeight / 10,
          maxHeight: NavigationConfig.navigationItemLabelLineHeight * 2,
          overflow: "hidden",
          wordBreak: "break-word",
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

const NavigationItem = ({
  icon,
  label,
  to,
  matchRoute,
  entireUrlMatch = true,
  analyticsPayload,
  analyticsUserInteractionPayload,
}: NavigationItemProps) => {
  const resolved = useResolvedPath(matchRoute || to);
  const isActiveItem = useMatch({
    path: resolved.pathname,
    end: entireUrlMatch,
  });

  function buildAnalyticsPayload() {
    if (analyticsPayload) {
      trackEvent(analyticsPayload);
    }
    if (analyticsUserInteractionPayload) {
      trackUserInteraction(analyticsUserInteractionPayload);
    }
  }

  return (
    <Button
      sx={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        p: 0,
        px: 0.5,
        height: NavigationConfig.navigationItemHeight,
        minWidth: NavigationConfig.navigationItemMinWidth,
        width: "100%",
        ":focus": {
          outline: "none",
        },
      }}
      color={isActiveItem ? "primary" : "neutral"}
      component={Link}
      variant="text"
      to={to}
      aria-label={label}
      onClick={buildAnalyticsPayload}
    >
      {icon}
      <Typography
        color="inherit"
        textAlign="center"
        component="p"
        variant="h6"
        sx={{
          mt: 0.5,
          flex: "0 0 auto",
          lineHeight: NavigationConfig.navigationItemLabelLineHeight / 10,
          maxHeight: NavigationConfig.navigationItemLabelLineHeight * 2,
          overflow: "hidden",
          wordBreak: "break-word",
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default function Navigation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useGlobalStore();

  const isRetailMember = state.currentUser.isRetailMember;

  const isSensUpdatesEnabled = isFeatureEnabled(
    FeatureToggleIdentifier.ENABLE_SENS_UPDATES
  );

  function onGetHelpClick() {
    trackEvent({
      event: "action.userNav",
      "userNav.destination": "getHelp",
      "userNav.via": "bottomNav",
    });

    trackUserInteraction({
      linkText: "Bottom navigation | Get help",
      linkIntent: "navigational",
      linkScope: "button",
    });

    navigate("/get-help", {
      state: {
        from: location,
      },
    });
  }

  return (
    <>
      <Stack
        bgcolor="background.paper"
        borderRadius={2}
        width="100%"
        sx={{
          boxShadow: NavigationConfig.boxShadow,
          p: `${NavigationConfig.padding}px`,
        }}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <NavigationItem
          to="/home"
          icon={
            <HomeIcon
              sx={{
                fontSize: convertPxToRem(
                  NavigationConfig.navigationItemIconSize
                ),
              }}
            />
          }
          analyticsUserInteractionPayload={{
            linkText: "Bottom navigation | Home",
            linkIntent: "navigational",
            linkScope: "button",
          }}
          label={t("BottomNavigation.home")}
        />
        <NavigationItem
          to="/home/my-health/health-profile"
          matchRoute="/home/my-health"
          entireUrlMatch={false}
          icon={
            <HeartIcon
              sx={{
                fontSize: convertPxToRem(
                  NavigationConfig.navigationItemIconSize
                ),
              }}
            />
          }
          analyticsUserInteractionPayload={{
            linkText: "Bottom navigation | My health",
            linkIntent: "navigational",
            linkScope: "button",
          }}
          analyticsPayload={{
            event: "action.userNav",
            "userNav.destination": "myHealth",
            "userNav.via": "bottomNav",
          }}
          label={
            !isRetailMember || !isSensUpdatesEnabled
              ? t("BottomNavigation.health")
              : t("BottomNavigation.myHealth")
          }
        />
        {!isSensUpdatesEnabled || !isRetailMember ? (
          <NavigationItem
            to="/home/my-benefits/benefits"
            matchRoute="/home/my-benefits"
            entireUrlMatch={false}
            icon={
              <HeartInShieldIcon
                sx={{
                  fontSize: convertPxToRem(
                    NavigationConfig.navigationItemIconSize
                  ),
                }}
              />
            }
            analyticsUserInteractionPayload={{
              linkText: "Bottom navigation | Benefits",
              linkIntent: "navigational",
              linkScope: "button",
            }}
            analyticsPayload={{
              event: "action.userNav",
              "userNav.destination": "myBenefits",
              "userNav.via": "bottomNav",
            }}
            label={t("BottomNavigation.myBenefits")}
          />
        ) : null}
        <GetHelpNavigationItem
          onClick={onGetHelpClick}
          icon={
            <CircleWithQuestionMark
              sx={{
                color: "primary.contrastText",
                fontSize: convertPxToRem(
                  NavigationConfig.navigationItemIconSize
                ),
              }}
            />
          }
          label={t("BottomNavigation.getHelp")}
        />
        <NavigationItem
          to="/home/more"
          icon={
            <DotsVerticalIcon
              sx={{
                fontSize: convertPxToRem(
                  NavigationConfig.navigationItemIconSize
                ),
              }}
            />
          }
          analyticsUserInteractionPayload={{
            linkText: "Bottom navigation | More",
            linkIntent: "navigational",
            linkScope: "button",
          }}
          label={t("BottomNavigation.more")}
        />
      </Stack>
    </>
  );
}
