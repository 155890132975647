import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconLoader from "@/features/Common/components/IconLoader";

interface ProfileIconCTAProps {
  onClick: () => void;
}

export default function ProfileIconCTA({ onClick }: ProfileIconCTAProps) {
  const { t } = useTranslation();

  return (
    <Stack
      component="button"
      onClick={onClick}
      sx={{ border: "none", cursor: "pointer", background: "transparent" }}
      aria-label={t("ProfileIconCTA.button.label")}
    >
      <IconLoader
        icon="UserIcon"
        sx={{
          color: "neutral.main",
          fontSize: 30,
        }}
      />
    </Stack>
  );
}
