import ItemPillSlider from "@/features/Common/components/ItemPillSlider";
import { DirectToDoctorPrimarySymptom } from "@/services/core-api-adapter";
import { useNavigate } from "react-router";
import DirectToDoctorPrimarySymptomsModal from "../DirectToDoctorPrimarySymptomsModal";
import { trackUserInteraction } from "@/services/analytics-adapter";
import { PRIMARY_SYMPTOM_PILL_MAP } from "@/features/Common/constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface DirectToDoctorSliderProps {
  primarySymptoms: DirectToDoctorPrimarySymptom[];
  maxItems?: number;
  displayViewAllButton?: boolean;
}

export default function DirectToDoctorSlider({
  primarySymptoms,
  maxItems,
  displayViewAllButton,
}: DirectToDoctorSliderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  function onPrimarySymptomClick(primarySymptom: DirectToDoctorPrimarySymptom) {
    navigate(
      `/home/get-medical-assistance/intercare?primarySymptomIds=${primarySymptom.id}`
    );
  }

  function handleTrackInteraction(item: DirectToDoctorPrimarySymptom) {
    trackUserInteraction({
      linkText: `DirectToDoctorPrimarySymptomsSlider | ${item.name}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  function onViewAllButtonClick() {
    setIsModalOpen(true);
    trackUserInteraction({
      linkText: "DirectToDoctorPrimarySymptomsSlider | View All",
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  return (
    <>
      <ItemPillSlider
        ariaLabel={t("DirectToDoctorSlider.title")}
        items={primarySymptoms}
        itemMap={PRIMARY_SYMPTOM_PILL_MAP}
        onItemClick={onPrimarySymptomClick}
        trackInteraction={handleTrackInteraction}
        maxItems={maxItems}
        displayViewAllButton={displayViewAllButton}
        onViewAllClick={onViewAllButtonClick}
      />

      <DirectToDoctorPrimarySymptomsModal
        primarySymptoms={primarySymptoms}
        itemMap={PRIMARY_SYMPTOM_PILL_MAP}
        onPrimarySymptomClick={onPrimarySymptomClick}
        isOpen={isModalOpen}
        onDialogClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
