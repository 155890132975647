import ItemPillSlider from "@/features/Common/components/ItemPillSlider";
import {
  DirectToDoctorPrimarySymptom,
  PathologyPanel,
} from "@/services/core-api-adapter";
import { useNavigate } from "react-router";
import { trackEvent, trackUserInteraction } from "@/services/analytics-adapter";
import useGetPanelsList from "@/features/Pathology/hooks/useGetPanelsList";
import { PATHOLOGY_PANEL_PILL_MAP } from "@/features/Common/constants";
import { useTranslation } from "react-i18next";

interface PathologyPanelSliderProps {
  panels?: PathologyPanel[];
  isModalOpen?: boolean;
  setIsModalOpen?: (arg: boolean) => void;
  maxItems?: number;
  displayViewAllButton?: boolean;
}

export default function PathologyPanelSlider({
  maxItems,
  displayViewAllButton,
}: PathologyPanelSliderProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function onPanelClick(pathologyPanel: PathologyPanel) {
    trackEvent({
      event: "action.pathologyPanelSelected",
      source: "Pathology panel slider",
      panelName: pathologyPanel.panelName,
    });
    navigate(
      `/home/get-medical-assistance/pathology?panelId=${pathologyPanel.panelId}`
    );
  }

  function onViewAllButtonClick() {
    trackEvent({
      event: "action.pathologyPanelsViewAll",
      source: "Pathology panel slider",
    });
    navigate("/home/get-medical-assistance/pathology");
  }

  function handleTrackInteraction(item: DirectToDoctorPrimarySymptom) {
    trackUserInteraction({
      linkText: `PathologyPanelSlider | ${item.name}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  const { panelList, panelListFetchError, isPanelListLoading } =
    useGetPanelsList();

  return (
    <>
      {!isPanelListLoading && !panelListFetchError && panelList?.length > 0 && (
        <>
          <ItemPillSlider
            ariaLabel={t("PathologyPanelsSlider.title")}
            onViewAllClick={onViewAllButtonClick}
            displayViewAllButton={displayViewAllButton}
            maxItems={maxItems}
            items={panelList}
            itemMap={PATHOLOGY_PANEL_PILL_MAP}
            onItemClick={onPanelClick}
            trackInteraction={handleTrackInteraction}
          />
        </>
      )}
    </>
  );
}
